import { usePage } from '@inertiajs/vue3';
import { route as baseRoute } from 'ziggy-js';

/**
 * Generate a URL based on the route name and parameters using Ziggy
 *
 * @param {string} name - The name of the route (e.g., 'home', 'user.show')
 * @param {Object} [params={}] - An object containing the route parameters (e.g., { id: 1 })
 * @param {boolean} [absolute=false] - Whether to generate an absolute URL (true) or a relative one (false)
 * @returns {string} The generated URL for the given route
 */
export function route(name, params = {}, absolute = false) {
    const page = usePage();

    const config = {
        ...page.props.ziggy,
        location: new URL(page.props.ziggy.location),
    };

    return baseRoute(
        `${page.props.app.locale.current}.${name}`,
        params,
        absolute,
        config
    );
}
