<script setup>
import {ref, computed, watch, onMounted, onBeforeUnmount} from 'vue';
import {trans} from "../Composables/trans.js";

const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';
const THEME_AUTO = 'auto';

const themeOptions = [
    {value: THEME_LIGHT, icon: 'bi-sun-fill', label: 'theme.light'},
    {value: THEME_DARK, icon: 'bi-moon-stars-fill', label: 'theme.dark'},
    {value: THEME_AUTO, icon: 'bi-circle-half', label: 'theme.auto'},
];

const theme = ref(THEME_AUTO);
const isDarkModePreferred = ref(false);
let mediaQuery = null;

const appliedTheme = computed(() => {
    return theme.value === THEME_AUTO
        ? isDarkModePreferred.value
            ? THEME_DARK
            : THEME_LIGHT
        : theme.value;
});

const currentIconClass = computed(() => {
    const option = themeOptions.find((opt) => opt.value === appliedTheme.value);
    return option ? option.icon : '';
});

const handleMediaQueryChange = (event) => {
    isDarkModePreferred.value = event.matches;
};

const setTheme = (newTheme) => {
    theme.value = newTheme;
    if (typeof window !== 'undefined') {
        localStorage.setItem('theme', newTheme);
    }
};

onMounted(() => {
    if (typeof window !== 'undefined') {

        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            theme.value = storedTheme;
        }

        mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        isDarkModePreferred.value = mediaQuery.matches;
        mediaQuery.addEventListener('change', handleMediaQueryChange);

        watch(
            appliedTheme,
            (newTheme) => {
                document.documentElement.setAttribute('data-bs-theme', newTheme);
            },
            {immediate: true}
        );
    }
});

onBeforeUnmount(() => {
    if (mediaQuery) {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
    }
});
</script>

<template>
    <div class="dropdown">
        <button
            class="btn btn-primary btn-icon d-flex align-items-center"
            type="button"
            data-bs-toggle="dropdown"
        >
            <i :class="['bi', currentIconClass]"/>
        </button>
        <ul class="dropdown-menu">
            <li v-for="option in themeOptions" :key="option.value">
                <button
                    type="button"
                    :class="[
                        'dropdown-item',
                        'd-flex',
                        'align-items-center',
                        { active: theme === option.value },
                    ]"
                    @click="setTheme(option.value)"
                >
                    <i :class="['bi', option.icon]"/>
                    <span class="ms-2">{{ trans(option.label) }}</span>
                </button>
            </li>
        </ul>
    </div>
</template>
