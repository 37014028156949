<script setup>
import {ref, onMounted, onUnmounted} from 'vue';

const path = ref(null);
const isActive = ref(false);

const updatePathOffset = () => {
    const pathElement = path.value;
    const pathLength = pathElement.getTotalLength();
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    pathElement.style.strokeDashoffset = pathLength - (scrollTop * pathLength) / scrollHeight;
    isActive.value = scrollTop >= 50;
};

const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
};

onMounted(() => {
    const pathElement = path.value;
    const pathLength = pathElement.getTotalLength();

    pathElement.style.strokeDasharray = `${pathLength} ${pathLength}`;
    pathElement.style.strokeDashoffset = pathLength;

    window.addEventListener('scroll', updatePathOffset);
});

onUnmounted(() => {
    window.removeEventListener('scroll', updatePathOffset);
});
</script>

<template>
    <div class="btn-scroll-top shadow" :class="{'active-progress': isActive}" @click="scrollToTop">
        <svg class="progress-square svg-content" width="100%" height="100%" viewBox="0 0 40 40">
            <path ref="path"
                  d="M8 1H32C35.866 1 39 4.13401 39 8V32C39 35.866 35.866 39 32 39H8C4.13401 39 1 35.866 1 32V8C1 4.13401 1 4.13401 8 1Z"></path>
        </svg>
    </div>
</template>
