<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';

const page = usePage();

const filterLocales = computed(() => page.props.filter_locales || null);

const locales = computed(() => page.props.app.locale.available_locales);
const urls = computed(() => page.props.app.locale.urls);

const currentLocale = computed(() => page.props.app.locale.current);
const currentLocaleName = computed(() => locales.value[currentLocale.value]);

const changeLocale = (locale) => {
    const url = urls.value[locale];

    if (url) {
        window.location.href = url;
    }
};

const isLocaleEnabled = (locale) => {
    return !filterLocales.value || filterLocales.value.includes(locale);
};
</script>

<template>
    <div class="dropdown">
        <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
        >
            {{ currentLocaleName }}
        </button>
        <ul class="dropdown-menu">
            <li v-for="(name, locale) in locales" :key="locale" :value="locale">
                <button
                    type="button"
                    :class="[
                        'dropdown-item d-flex align-items-center',
                        { 'active': currentLocale === locale, 'disabled': !isLocaleEnabled(locale) }
                    ]"
                    :disabled="!isLocaleEnabled(locale)"
                    @click.prevent="isLocaleEnabled(locale) && changeLocale(locale)"
                >
                    <img
                        :src="`/assets/images/languages/${locale}.svg`"
                        width="20"
                        class="me-2"
                        alt="Flag"
                    >
                    {{ name }}
                </button>
            </li>
        </ul>
    </div>
</template>
