<script setup>
import {ref, onMounted} from 'vue';
import {usePage} from '@inertiajs/vue3';

const page = usePage();

const {available_locales: locales, urls, current: currentLocale} = page.props.app.locale;

const browserLocale = typeof navigator !== 'undefined' && navigator.language
    ? navigator.language.split('-')[0].toLowerCase()
    : 'en';

const selectedLocale = ref(browserLocale);
const shouldShowAlert = ref(false);

const translations = {
    en: {
        change_language: 'Change language?',
        incorrect_language_detected:
            'We have detected that your browser is set to a different language. You can change the site language to your preferred one',
        change: 'Change',
        close: 'Close',
    },
    ru: {
        change_language: 'Изменить язык?',
        incorrect_language_detected:
            'Мы заметили, что ваш браузер настроен на другой язык. Вы можете изменить язык сайта на предпочитаемый',
        change: 'Изменить',
        close: 'Закрыть',
    },
    fr: {
        change_language: 'Changer la langue ?',
        incorrect_language_detected:
            'Nous avons détecté que votre navigateur est configuré dans une autre langue. Vous pouvez changer la langue du site pour celle de votre choix',
        change: 'Changer',
        close: 'Fermer',
    },
    de: {
        change_language: 'Sprache ändern?',
        incorrect_language_detected:
            'Wir haben festgestellt, dass Ihr Browser auf eine andere Sprache eingestellt ist. Sie können die Sprache der Website auf Ihre bevorzugte ändern',
        change: 'Ändern',
        close: 'Schließen',
    },
    ja: {
        change_language: '言語を変更しますか？',
        incorrect_language_detected:
            'お使いのブラウザが別の言語に設定されていることを検出しました。お好みの言語にサイトの言語を変更できます',
        change: '変更する',
        close: '閉じる',
    },
};

const translate = (key) => translations[browserLocale]?.[key] || translations.en[key] || key;

const changeLocale = () => {
    const url = urls[selectedLocale.value];
    if (url) window.location.href = url;
};

const closeAlert = () => {
    shouldShowAlert.value = false;
    sessionStorage.setItem('languageAlertClosed', 'true');
};

onMounted(() => {
    if (
        !sessionStorage.getItem('languageAlertClosed') &&
        locales[browserLocale] &&
        browserLocale !== currentLocale
    ) {
        shouldShowAlert.value = true;
    }
});
</script>

<template>
    <div v-if="shouldShowAlert" class="position-fixed bottom-0 end-0 px-3" style="z-index: 1020; max-width: 500px;">
        <div class="alert bg-white shadow w-100" role="alert">
            <div class="row align-items-center">
                <div class="col-12 mb-2">
                    <h5 class="text-dark">
                        {{ translate('change_language') }} <i class="bi bi-translate text-primary ms-1"></i>
                    </h5>
                    <p class="mb-0">
                        {{ translate('incorrect_language_detected') }}
                    </p>
                </div>
                <div class="col-12">
                    <div class="d-flex flex-column h-100 justify-content-between">
                        <select v-model="selectedLocale" class="form-select mb-2 w-100">
                            <option v-for="(name, locale) in locales" :key="locale" :value="locale">
                                {{ name }}
                            </option>
                        </select>
                        <div class="d-flex gap-2 w-100">
                            <button class="btn btn-primary w-100" @click="changeLocale">
                                {{ translate('change') }}
                            </button>
                            <button class="btn btn-secondary w-100" @click="closeAlert">
                                {{ translate('close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

